<div fxLayout="column"
     fxLayoutAlign="space-around center">
  <h1 class="info-clause-up">{{'mfaHomePage.title'| translate}}</h1>
  <div>
    <img class="logo"
         src="assets/images/SYNLAB_logo_PNG.png"
         alt="Synlab logo">
  </div>
  <div fxLayout="column"
       fxLayoutAlign="space-around center">
    <button mat-raised-button
            class="login-button"
            (click)="navigateToLoginMfa()">{{'common.buttons.login'|translate}}
    </button>
    <button mat-stroked-button
            class="help"
            (click)="openDialog()">{{'common.buttons.help'|translate}}
    </button>
  </div>
  <p class="version">Versioon: {{ softwareVersion }}</p>
</div>


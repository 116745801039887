<div class="container">
  <h1 mat-dialog-title>{{ 'helpDialog.title' | translate }}</h1>
  <div mat-dialog-content>
    <p>
      <a href="{{'helpDialog.links.synlabWebsite'|translate}}"
         target="_blank">{{ 'helpDialog.content.paragraph1Part1' | translate }}</a>
      {{ 'helpDialog.content.paragraph1Part2' | translate }}
    </p>
    <p>{{ 'helpDialog.content.paragraph2' | translate }}</p>

    <mat-list>
      <ng-container>
        <mat-list-item class="mat-list-content">{{ 'helpDialog.content.listItem1' | translate }}</mat-list-item>
        <mat-list class="padding-top-0">
          <div class="bullet-list">
            <ul>
              <li>
                <p>
                  <a href="{{ 'helpDialog.links.application1Link' | translate }}"
                     target="_blank">{{ 'helpDialog.content.application1Part1' | translate }}</a>
                  {{ 'helpDialog.content.application1Part2' | translate }}
                </p>
              </li>
              <li>
                <p>
                  <a href="{{ 'helpDialog.links.application2Link' | translate }}"
                     target="_blank">{{ 'helpDialog.content.application2Part1' | translate }}</a>
                  {{ 'helpDialog.content.application2Part2' | translate }}
                </p>
              </li>
              <li>
                <p>
                  <a href="{{ 'helpDialog.links.application3Link' | translate }}"
                     target="_blank">{{ 'helpDialog.content.application3Part1' | translate }}</a>
                  {{ 'helpDialog.content.application3Part2' | translate }}
                </p>
              </li>
            </ul>

          </div>
        </mat-list>
        <mat-list-item class="mat-list-content">{{ 'helpDialog.content.listItem2' | translate }}</mat-list-item>
        <mat-list-item class="mat-list-content">{{ 'helpDialog.content.listItem3' | translate }}&nbsp;
          <a href="mailto: {{'helpDialog.links.email'|translate}}">{{'helpDialog.links.email'|translate}}</a>
        </mat-list-item>
      </ng-container>
    </mat-list>

    <p>{{ 'helpDialog.content.additionalInfoPart1' | translate }}
      <a href="mailto: {{'helpDialog.links.email' | translate}}">{{ 'helpDialog.links.email' | translate }}</a>,
      {{ 'helpDialog.content.additionalInfoPart2' | translate }}
    </p>
    <p>{{ 'helpDialog.content.loginIssue' | translate }}
      <a href="{{'helpDialog.links.idInfoLink'|translate}}">{{ 'helpDialog.content.here' | translate }}</a>.
      {{ 'helpDialog.content.generalInfo' | translate }}
      <a href="{{'helpDialog.links.generalIdCardInfoLink'|translate}}">{{ 'helpDialog.content.here' | translate }}</a>.
    </p>
  </div>
  <div mat-dialog-actions class="button">
    <button mat-stroked-button (click)="closeDialog()">{{ 'common.buttons.close' | translate }}</button>
  </div>
</div>
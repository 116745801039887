import {Component} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../services/authentication.service";
import {LoginPageMfaHelpComponent} from "../../components/login-page-mfa-help/login-page-mfa-help.component";
import {AppRoutes} from "../../utils/app-routes";
import {SOFTWARE_VERSION} from "../../utils/version";
import {TranslateService} from "@ngx-translate/core";
import {DateAdapter} from "@angular/material/core";

@Component({
  selector: 'app-login-page-mfa',
  templateUrl: './login-page-mfa.component.html',
  styleUrls: ['./login-page-mfa.component.css']
})
export class LoginPageMfaComponent {
  softwareVersion = SOFTWARE_VERSION;

  constructor(private dialog: MatDialog,
              private router: Router,
              private authenticationService: AuthenticationService,
              private translate: TranslateService,
              private dateAdapter: DateAdapter<any>
  ) {
    if (!localStorage.getItem("userLanguage")) {
      translate.use('en');
      this.dateAdapter.setLocale('en-US');
    }
    if (this.authenticationService.authenticatedUserValue) {
      this.router.navigate([AppRoutes.Results]);
    }
  }

  openDialog(): void {
    this.dialog.open(LoginPageMfaHelpComponent, {});
  }

  navigateToLoginMfa() {
    this.router.navigate([AppRoutes.MfaCredentials]);
  }
}

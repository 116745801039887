<mat-form-field class="multiple-pick-dialog">
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="userInstitutionMultiCtrl" [compareWith]="compareUserInstitutions" multiple disableOptionCentering>
    <mat-option>
      <ngx-mat-select-search [formControl]="userInstitutionMultiFilterCtrl"
                             [noEntriesFoundLabel]="isLoading ? ('common.labels.searching' | translate) : ('common.labels.noEntriesFoundLabel' | translate)"
                             placeholderLabel="{{ 'common.labels.searchByDoctorCode' | translate }}">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let userInstitution of filteredUserInstitutions | async" [value]="userInstitution">
      {{displayDoctorCodeWithInstitution(userInstitution)}}
    </mat-option>
  </mat-select>
</mat-form-field>



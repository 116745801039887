import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AuthenticationService} from './services/authentication.service';
import {LoginDetails} from './models/login-details';
import {containerRoutes, headerRoutes} from "./utils/app-routes";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Synlab';
  showHeader: boolean;
  showContainer: boolean;
  authenticatedUser: LoginDetails;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private translate: TranslateService
  ) {
    this.authenticationService.authenticatedUser.subscribe(user => this.authenticatedUser = user);
    const userLang = localStorage.getItem('userLanguage') || 'et';
    this.translate.setDefaultLang(userLang);
    this.translate.use(userLang);
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.showHeader = headerRoutes.includes(event.url.slice(1));
        this.showContainer = containerRoutes.includes(event.url.slice(1));
      } else if (event instanceof NavigationEnd) {
        const {code, state} = this.activatedRoute.snapshot.queryParams;
        if (code && state) {
          this.authenticationService.tryToGetAuthTokenAndNavigate(code, state);
        }
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/user.service";
import {MatDialogRef} from "@angular/material/dialog";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {PASSWORD_PATTERN} from "../../utils/regex-patterns";
import {PasswordChange} from "../../models/password-change";
import {snackbarConfig, SnackBarType} from "../../utils/snackbar-utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {PASSWORD_CHANGE_FAILED, PASSWORD_SAME_AS_CURRENT} from "../../utils/error-messages";

@Component({
  selector: 'app-password-change-dialog',
  templateUrl: './password-change-dialog.component.html',
  styleUrls: ['./password-change-dialog.component.css']
})
export class PasswordChangeDialogComponent implements OnInit {
  passwordForm: FormGroup;
  hideOldPassword: boolean = true;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<PasswordChangeDialogComponent>,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.initializePasswordForm();
  }

  initializePasswordForm() {
    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern(PASSWORD_PATTERN)]],
      confirmPassword: ['', [Validators.required, this.confirmPasswordValidator()]],
    });
  }

  changePassword() {
    if (this.passwordForm.invalid) {
      return
    }

    const passwordChange: PasswordChange = {
      oldPassword: this.passwordForm.get('oldPassword').value,
      newPassword: this.passwordForm.get('newPassword').value
    }

    this.userService.changePassword(passwordChange).subscribe({
      next: () => {
        this.openSnackBar(this.translate.instant('passwordChangeDialog.successMessages.passwordChangeSuccess'), SnackBarType.success);
        this.closeDialog();
      },
      error: (error) => {
        if (error === PASSWORD_CHANGE_FAILED) {
          this.openSnackBar(this.translate.instant('passwordChangeDialog.apiErrors.passwordChangeFailed'), SnackBarType.error);
        } else if (error === PASSWORD_SAME_AS_CURRENT) {
          this.openSnackBar(this.translate.instant('passwordChangeDialog.apiErrors.passwordSameAsCurrent'), SnackBarType.error);
        } else {
          this.openSnackBar(this.translate.instant('passwordChangeDialog.apiErrors.saveChangesFailed'), SnackBarType.error);
        }
      }
    })
  }

  confirmPasswordValidator(): ValidatorFn {
    return (confirmPasswordControl: AbstractControl): ValidationErrors | null => {
      if (!this.passwordForm) return null;
      const newPasswordControl = this.passwordForm.get('newPassword');
      if (!newPasswordControl) return null;
      return newPasswordControl.value === confirmPasswordControl.value ? null : { 'mismatch': true };
    };
  }

  getErrorMessage(fieldName: string): string {
    const control = this.passwordForm.get(fieldName);
    if (!control) {
      return '';
    }
    if (control.hasError('required')) {
      return this.translate.instant('passwordChangeDialog.validationErrors.required');
    }
    if (control.hasError('pattern')) {
      return this.translate.instant('passwordChangeDialog.validationErrors.pattern');
    }
    if (control.hasError('mismatch')) {
      return this.translate.instant('passwordChangeDialog.validationErrors.mismatch');
    }
    return '';
  }


  openSnackBar(message: string, type: SnackBarType) {
    const config = snackbarConfig(type);
    this.snackBar.open(message, null, config);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

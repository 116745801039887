<h2 mat-dialog-title>{{'deleteResultDialog.title' | translate}}</h2>
<p>{{'deleteResultDialog.resultCodeLabel' | translate}} {{data.resultCode}}</p>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" fxLayoutGap="20px" fxLayoutGap.xs="2px">

  <button mat-button (click)="closeDialogAndPostResult()" mat-raised-button
          class="confirm-button">{{'common.buttons.yes' | translate}}</button>
  <button mat-button (click)="closeDialogWithoutPost()" mat-stroked-button
          mat-dialog-close>{{'common.buttons.no' | translate}}</button>

</div>

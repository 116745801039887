<mat-form-field class="multiple-pick-dialog">
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="institutionMultiCtrl" [compareWith]="compareInstitutions" multiple disableOptionCentering>
    <mat-option>
      <ngx-mat-select-search [formControl]="institutionMultiFilterCtrl"
                             [noEntriesFoundLabel]="isLoading ? ('common.labels.searching' | translate) : ('common.labels.noEntriesFoundLabel' | translate)"
                             placeholderLabel="{{ 'common.labels.searchByInstitutionName' | translate }}">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let institution of filteredInstitutions | async" [value]="institution">
      {{ displayInstitutionName(institution)}}
    </mat-option>
  </mat-select>
</mat-form-field>

import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from "@ngx-translate/core";

export function getPaginatorIntl(translateService: TranslateService) {
  const paginatorIntl = new MatPaginatorIntl();

  const updateLabels = () => {
    paginatorIntl.itemsPerPageLabel = translateService.instant('paginator.itemsPerPageLabel');
    paginatorIntl.nextPageLabel = translateService.instant('paginator.nextPageLabel');
    paginatorIntl.previousPageLabel = translateService.instant('paginator.previousPageLabel');
    paginatorIntl.firstPageLabel = translateService.instant('paginator.firstPageLabel');
    paginatorIntl.lastPageLabel = translateService.instant('paginator.lastPageLabel');
    paginatorIntl.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return `0 | ${length}`;
      }
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return translateService.instant('paginator.rangeLabel', {
        startIndex: startIndex + 1,
        endIndex: endIndex,
        length: length
      });
    };
  };
  updateLabels();

  translateService.onLangChange.subscribe(() => {
    updateLabels();
    paginatorIntl.changes.next();
  });

  return paginatorIntl;
}

<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Language">
  <mat-icon>language</mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item (click)="switchLanguage('et')">
    {{'header.languageEt' | translate}}
  </button>
  <button mat-menu-item (click)="switchLanguage('en')">
    {{'header.languageEn' | translate}}
  </button>
</mat-menu>
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {AppRoutes} from "../../utils/app-routes";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-failure.component.html',
  styleUrls: ['./login-failure.component.css']
})
export class LoginFailureComponent implements OnInit {

  error: string;

  constructor(private dialog: MatDialog,
              private httpClient: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService
  ) {
    if (LoginFailureComponent.getAuthenticatedUser() != null) {
      this.router.navigate([AppRoutes.Results]);
    }
  }

  private static getAuthenticatedUser() {
    return JSON.parse(localStorage.getItem('authenticatedUser'));
  }

  ngOnInit() {
    this.displayError();
  }

  private displayError(): void {
    this.route.queryParams
    .subscribe(params => {
        this.error = params.error;
      }
    );
  }

  login(): void {
    this.authenticationService.tryAuthenticateWithTara();
  }
}

<div fxLayout="column"
     fxLayoutAlign="space-around center">
  <h1 class="info-clause-up">{{'homePage.title' | translate}}</h1>
  <div>
    <img class="logo"
         src="assets/images/SYNLAB_logo_PNG.png"
         alt="Synlab logo">
  </div>
  <div fxLayout="column"
       fxLayoutAlign="space-around center">
    <button mat-raised-button
            class="login-button"
            (click)="login()">{{'common.buttons.login'| translate}}
    </button>
    <button mat-stroked-button
            class="help"
            (click)="openDialog()">{{'common.buttons.help'| translate}}
    </button>
  </div>
  <p class="version">{{'common.other.version'| translate}}: {{ softwareVersion }}</p>
</div>
<p class="info-clause-down">{{'homePage.titleExplainer'|translate}}<a href="https://minu.synlab.ee/"
                                                                      target="_blank">MINU.SYNLAB.EE</a></p>

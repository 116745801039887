export enum Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export function getStatusViewName(status: Status) {
  switch (status) {
    case Status.Active:
      return 'Aktiivne';
    case Status.Inactive:
      return 'Mitteaktiivne';
  }
}

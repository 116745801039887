import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

import {Role} from '../models/role';
import {JwtToken} from '../models/jwt-token';
import {NavigationEnd, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LoginDetails} from '../models/login-details';
import {SessionMonitoringService} from './session-monitoring.service';
import {now} from 'moment/moment';
import {MfaLoginRequest, MfaVerificationRequest} from "../models/mfa-data";
import {AppRoutes} from "../utils/app-routes";
import {TranslateService} from "@ngx-translate/core";

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  public authenticatedUser: Observable<LoginDetails>;
  private authenticationResponseSubject: BehaviorSubject<LoginDetails>;
  private _mfaId: number | null = null;

  private logoutUrl = 'api/v1/user/logout';
  private loginBaseUrl: string = 'api/login';
  private navigationReason: string;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private jwtHelperService: JwtHelperService,
    private sessionMonitoringService: SessionMonitoringService,
    private translateService: TranslateService
  ) {
    this.authenticationResponseSubject =
      new BehaviorSubject<LoginDetails>(this.authenticatedUserFromToken);
    this.authenticatedUser = this.authenticationResponseSubject.asObservable();
    router.events.subscribe((value) => {
      if (value instanceof NavigationEnd && this.navigationReason && this.navigationReason === 'session timeout' && value.url === '/') {
        this.snackBar.open(this.translateService.instant("common.other.sessionTimeout"), '', {duration: 10000});
      }
    });
  }

  get mfaId(): number | null {
    return this._mfaId;
  }

  set mfaId(value: number | null) {
    this._mfaId = value;
  }

  get authenticatedUserValue(): LoginDetails {
    return this.authenticationResponseSubject.value;
  }

  get authenticatedUserFromToken(): LoginDetails {
    return this.jwtHelperService.decodeToken(this.getJwtToken);
  }

  get getJwtToken(): string {
    return localStorage.getItem('token');
  }

  tryToGetAuthTokenAndNavigate(code: string, state: string): void {
    this.getAuthToken(code, state).subscribe({
      next: (jwtTokenResponse: JwtToken) => {
        this.initializeAuthenticatedSession(jwtTokenResponse.jwtToken);
        this.router.navigate([AppRoutes.Results]);
      },
      error: (error: string) => {
        this.router.navigate([AppRoutes.LoginFailure], {queryParams: {error}});
      }
    });
  }

  tryAuthenticateWithTara(): void {
    this.getAuthRedirectUrl().subscribe({
      next: (resp: string) => {
        window.location.href = resp;
      },
      error: (error: string) => {
        this.router.navigate([AppRoutes.LoginFailure], {queryParams: {error}});
      }
    });
  }

  mfaLoginWithCredentials(mfaLoginRequest: MfaLoginRequest) {
    return this.httpClient.post(`${this.loginBaseUrl}/mfa-login`, mfaLoginRequest);
  }

  verifyMfaPinCode(mfaVerificationRequest: MfaVerificationRequest) {
    return this.httpClient.post(`${this.loginBaseUrl}/mfa-verify-pin`, mfaVerificationRequest);
  }

  clearMfaId() {
    this._mfaId = null;
  }

  initializeAuthenticatedSession(token: string): void {
    localStorage.setItem('token', token);
    const tokenData = this.jwtHelperService.decodeToken(token);
    this.authenticationResponseSubject.next(tokenData);
    this.sessionMonitoringService.initListener(now());
  }

  logoutAndFinishSession(): void {
    if (this.authenticatedUserValue) {
      const mfaEnabled = this.authenticatedUserValue.mfaEnabled;
      this.httpClient.post(this.logoutUrl, {}).subscribe(() => {
        localStorage.removeItem('token');
        this.authenticationResponseSubject.next(null);
        this.router.navigate([mfaEnabled ? AppRoutes.MfaHome : AppRoutes.Home]);
      });
    }
  }

  logout(reason?: string): void {
    if (this.authenticatedUserValue) {
      const mfaEnabled = this.authenticatedUserValue.mfaEnabled;
      localStorage.removeItem('token');
      localStorage.removeItem('userLanguage');
      this.authenticationResponseSubject.next(null);
      if (reason) {
        this.navigationReason = reason;
      }
      this.router.navigate([mfaEnabled ? AppRoutes.MfaHome : AppRoutes.Home]);
    }
  }

  isAdmin(): boolean {
    return this.authenticatedUserValue && this.authenticatedUserValue.role === Role.Admin;
  }

  isMfaEnabled(): boolean {
    return this.authenticatedUserValue && this.authenticatedUserValue.mfaEnabled === true;
  }

  isAdminOrAg(): boolean {
    return this.authenticatedUserValue && (this.authenticatedUserValue.role === Role.Admin || this.authenticatedUserValue.role === Role.AG);
  }

  private getAuthRedirectUrl(): Observable<string> {
    return this.httpClient.get(`api/login/authenticate`, {observe: 'body', responseType: 'text'});
  }

  private getAuthToken(code: string, state: string): Observable<JwtToken> {
    return this.httpClient.get<JwtToken>(`api/login/token`, {params: {code, state}});
  }
}

import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {snackbarConfig, SnackBarType} from "../../utils/snackbar-utils";
import {MatDialog} from "@angular/material/dialog";
import {LoginPageMfaHelpComponent} from "../../components/login-page-mfa-help/login-page-mfa-help.component";
import {MfaVerificationRequest} from "../../models/mfa-data";
import {finalize} from "rxjs";
import {AuthenticationService} from "../../services/authentication.service";
import {JwtToken} from "../../models/jwt-token";
import {EXPIRED, INVALID_PIN, THROTTLED} from "../../utils/error-messages";
import {AppRoutes} from "../../utils/app-routes";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-login-page-verification-mfa',
  templateUrl: './login-page-mfa-verification.component.html',
  styleUrls: ['./login-page-mfa-verification.component.css']
})
export class LoginPageMfaVerificationComponent implements OnInit, AfterViewInit {
  mfaVerificationForm: FormGroup;
  isLoading: boolean = false;
  @ViewChild('pinCodeInput') pinCodeInput: ElementRef;

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    if (!this.authenticationService.mfaId) {
      this.router.navigate([AppRoutes.MfaHome]);
    }
    this.initializeVerificationForm();
  }

  ngAfterViewInit() {
    this.pinCodeInput.nativeElement.focus();
  }

  initializeVerificationForm() {
    this.mfaVerificationForm = this.formBuilder.group({
      pinCode: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  submitPinVerification() {
    if (this.mfaVerificationForm.valid) {
      this.isLoading = true;
      const mfaVerificationRequest: MfaVerificationRequest = {
        pinCode: this.mfaVerificationForm.get('pinCode').value,
        mfaId: this.authenticationService.mfaId
      }
      this.authenticationService.verifyMfaPinCode(mfaVerificationRequest)
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe({
          next: (response: JwtToken) => {
            if (response.jwtToken) {
              this.authenticationService.initializeAuthenticatedSession(response.jwtToken);
              this.authenticationService.clearMfaId();
              this.router.navigate([AppRoutes.Results]);
            }
          },
          error: (error) => {
            switch (error) {
              case INVALID_PIN:
                this.openSnackBar(this.translate.instant('mfaPinVerificationPage.apiError.invalidPin'), SnackBarType.error);
                break;
              case THROTTLED:
                this.abortAuthentication(this.translate.instant('mfaPinVerificationPage.apiError.throttled'));
                break;
              case EXPIRED:
                this.abortAuthentication(this.translate.instant('mfaPinVerificationPage.apiError.expired'));
                break;
              default:
                this.abortAuthentication(this.translate.instant('mfaPinVerificationPage.apiError.general'));
            }
          },
        })

    }
  }

  abortAuthentication(message: string) {
    this.openSnackBar(message, SnackBarType.error);
    this.authenticationService.clearMfaId();
    this.router.navigate([AppRoutes.MfaHome]);
  }

  getErrorMessage(fieldName: string): string {
    const control = this.mfaVerificationForm.get(fieldName);
    if (!control) {
      return '';
    }
    if (control.hasError('required')) {
      return this.translate.instant('mfaPinVerificationPage.validationError.required');
    }
    if (control.hasError('minlength')) {
      return this.translate.instant('mfaPinVerificationPage.validationError.shortPin');
    }
    return '';
  }

  openSnackBar(message: string, type: SnackBarType) {
    const config = snackbarConfig(type);
    this.snackBar.open(message, null, config);
  }

  openDialog(): void {
    this.dialog.open(LoginPageMfaHelpComponent, {});
  }
}
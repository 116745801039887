import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../models/page';
import {User, UserCreateUpdate, UserDetails} from '../models/user';
import {PasswordChange} from "../models/password-change";

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private baseUrl = 'api/v1/user';

  constructor(private httpClient: HttpClient) {
  }

  getUsers(params: any): Observable<Page<User>> {
    return this.httpClient.get<Page<User>>(this.baseUrl, {params});
  }

  getUserById(userId: number): Observable<UserDetails> {
    return this.httpClient.get<UserDetails>(`${this.baseUrl}/${userId}`);
  }

  createUser(user: UserCreateUpdate) {
    return this.httpClient.post(this.baseUrl, user);
  }

  updateUser(user: UserCreateUpdate) {
    return this.httpClient.put(`${this.baseUrl}/${user.id}`, user);
  }

  changePassword(passwordChange: PasswordChange) {
    return this.httpClient.put(`${this.baseUrl}/password`, passwordChange)
  }
}

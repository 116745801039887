import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-login-page-help-mfa',
  templateUrl: './login-page-mfa-help.component.html',
  styleUrls: ['./login-page-mfa-help.component.css']
})
export class LoginPageMfaHelpComponent {

  constructor(public dialogRef: MatDialogRef<LoginPageMfaHelpComponent>) {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

export enum Role {
  User = 'USER',
  Admin = 'ADMIN',
  AG = 'AG'
}

export function getViewName(role: Role) {
  if (role === Role.User) {
    return 'Oma tellimuste vaatamise õigus';
  }
  return 'Oma tellimuste ja arvelisade vaatamise õigus';
}

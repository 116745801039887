import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LoginPageHelpComponent} from '../../components/login-page-help/login-page-help.component';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {AppRoutes} from "../../utils/app-routes";
import {SOFTWARE_VERSION} from "../../utils/version";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent {
  softwareVersion = SOFTWARE_VERSION;

  constructor(private dialog: MatDialog,
              private router: Router,
              private authenticationService: AuthenticationService) {
    if (this.authenticationService.authenticatedUserValue) {
      this.router.navigate([AppRoutes.Results]);
    }
  }

  login(): void {
    this.authenticationService.tryAuthenticateWithTara();
  }

  openDialog(): void {
    this.dialog.open(LoginPageHelpComponent, {});
  }
}

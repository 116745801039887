<div class="main-content">
  <div fxLayout="row" fxLayoutAlign="start" class="warning">
    <h2>{{ 'resultsPage.info.message' | translate }}</h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px"
       fxLayout.lt-md="column" fxLayoutAlign.lt-md="center stretch" fxLayoutGap.lt-md="15px">
    <div fxFlex="15%" class="filters">
      <div>
        <mat-form-field>
          <label>
            <input [max]="searchFilter.maxDate" matInput (dateChange)="getMinDate($event)" [matDatepicker]="firstDate"
                   placeholder="{{ 'resultsPage.labels.from' | translate }}"
                   [formControl]="searchFilter.startDate">
          </label>
          <mat-datepicker-toggle matSuffix [for]="firstDate"></mat-datepicker-toggle>
          <mat-datepicker #firstDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <label>
            <input [min]="searchFilter.minDate" matInput (dateChange)="getMaxDate($event)" [matDatepicker]="secondDate"
                   placeholder="{{ 'resultsPage.labels.to' | translate }}"
                   [formControl]="searchFilter.endDate">
          </label>
          <mat-datepicker-toggle matSuffix [for]="secondDate"></mat-datepicker-toggle>
          <mat-datepicker #secondDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div fxFlex="20%" class="filters">
      <div>
        <mat-form-field>
          <mat-label>{{ 'common.labels.selectInstitution' | translate }}</mat-label>
          <mat-select [(ngModel)]="searchFilter.selectedInstitutionCode">
            <mat-option [value]="''">{{ 'common.labels.allInstitutions' | translate }}</mat-option>
            <mat-option *ngFor="let institution of searchFilter.institutions" [value]="institution.code">
              {{displayInstNameOrCode(institution)}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <app-requester-filter-dropdown
          (selectedDoctorCode)="onRequesterSelection($event)"></app-requester-filter-dropdown>
      </div>
    </div>

    <div fxFlex="20%" class="filters">
      <div>
        <mat-form-field>
          <mat-label>{{ 'resultsPage.labels.sort' | translate }}</mat-label>
          <mat-select [(ngModel)]="searchFilter.orderType">
            <mat-option *ngFor="let order of orderOptions" [value]="order.value">
              {{order.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div fxFlex="25%" class="filters">
      <div>
        <mat-form-field class="search-bar" appearance="outline">
          <mat-label>{{ 'resultsPage.labels.search' | translate }}</mat-label>
          <input matInput placeholder='' [(ngModel)]='searchFilter.query' (keydown.enter)="search()">
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>

      <div fxLayoutAlign="space-between">
        <button (click)="emptySearch()" mat-stroked-button
                class="empty-search">{{ 'common.buttons.clear' | translate }}</button>
        <button (click)="search()" mat-raised-button
                class="search-button">{{ 'common.buttons.search' | translate }}</button>
      </div>

    </div>
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ 'resultsPage.tableHeaders.date' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{changeDateFormat(element.orderDate)}}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>{{ 'resultsPage.tableHeaders.resultDate' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{changeDateFormat(element.createdAt)}}</td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>{{ 'resultsPage.tableHeaders.firstName' | translate }}</th>
      <td mat-cell
          *matCellDef="let element">{{shortString(element.patient.firstName)}}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>{{ 'resultsPage.tableHeaders.lastName' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{shortString(element.patient.lastName)}}</td>
    </ng-container>

    <ng-container matColumnDef="idCode">
      <th mat-header-cell *matHeaderCellDef>{{ 'resultsPage.tableHeaders.idCode' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.patient.idCode}}</td>
    </ng-container>

    <ng-container matColumnDef="doctorCode">
      <th mat-header-cell *matHeaderCellDef>{{ 'resultsPage.tableHeaders.doctorCode' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.requestedBy}}</td>
    </ng-container>

    <ng-container matColumnDef="order">
      <th mat-header-cell *matHeaderCellDef>{{ 'resultsPage.tableHeaders.order' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{shortString(element.resultCode)}}</td>
    </ng-container>

    <ng-container matColumnDef="topic">
      <th mat-header-cell *matHeaderCellDef>{{ 'resultsPage.tableHeaders.topic' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{shortString(element.field)}}</td>
    </ng-container>

    <ng-container matColumnDef="openResult">
      <th class="openResultHeader" mat-header-cell
          *matHeaderCellDef>{{ 'resultsPage.actions.openResult' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <a mat-button (click)="getPdf(element.id)">
          <mat-icon *ngIf="!element.opened">description</mat-icon>
          <img *ngIf="element.opened" ngSrc="./assets/images/description_outlined.svg"
               alt="Description Icon" class="mat-icon" height="24" width="24">
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="deleteResult">
      <th mat-header-cell *matHeaderCellDef>{{ 'resultsPage.actions.deleteResult' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <button mat-button (click)="openDeleteResultDialog(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        [ngClass]="{
        'make-light-blue' : !element.white,
        'unopened-results-bold': !element.opened
        }"></tr>
  </table>

  <mat-paginator [pageSize]='searchFilter.pageSize'
                 [length]='searchFilter.totalResults'
                 showFirstLastButtons></mat-paginator>

</div>

import {Component} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  constructor(private authenticationService: AuthenticationService) {
  }

  getName() {
    const authenticatedUser = this.getAuthenticatedUser();
    if (authenticatedUser != null) {
      return authenticatedUser.firstName + ' ' + authenticatedUser.lastName;
    }
  }

  isAuthenticated() {
    return this.getAuthenticatedUser() !== null;
  }

  getAuthenticatedUser() {
    return this.authenticationService.authenticatedUserValue;
  }

}

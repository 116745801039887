import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {snackbarConfig, SnackBarType} from "../../utils/snackbar-utils";
import {MatDialog} from "@angular/material/dialog";
import {LoginPageMfaHelpComponent} from "../../components/login-page-mfa-help/login-page-mfa-help.component";
import {AuthenticationService} from "../../services/authentication.service";
import {MfaId, MfaLoginRequest} from "../../models/mfa-data";
import {finalize} from "rxjs";
import {AppRoutes} from "../../utils/app-routes";
import {INVALID_CREDENTIALS} from "../../utils/error-messages";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-login-page-credentials-mfa',
  templateUrl: './login-page-mfa-credentials.component.html',
  styleUrls: ['./login-page-mfa-credentials.component.css'],
})

export class LoginPageMfaCredentialsComponent implements OnInit {
  mfaLoginForm: FormGroup;
  hidePassword: boolean = true;
  isLoading: boolean = false;

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService
  ) {
  }


  ngOnInit(): void {
    this.initializePasswordForm();
  }

  initializePasswordForm() {
    this.mfaLoginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  submitCredentials() {
    if (this.mfaLoginForm.valid) {
      this.isLoading = true;
      const mfaLoginRequest: MfaLoginRequest = {
        email: this.mfaLoginForm.get('email').value,
        password: this.mfaLoginForm.get('password').value
      }
      this.authenticationService.mfaLoginWithCredentials(mfaLoginRequest)
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe({
          next: (response: MfaId) => {
            this.authenticationService.mfaId = response.mfaId;
            this.router.navigate([AppRoutes.MfaPinVerification]);
          },
          error: (error) => {
            if (error === INVALID_CREDENTIALS) {
              this.openSnackBar(this.translate.instant('mfaCredentialsPage.apiError.invalidEmailOrPassword'), SnackBarType.error);
            } else {
              this.openSnackBar(this.translate.instant('mfaCredentialsPage.apiError.somethingWentWrong'), SnackBarType.error);
            }
          },
        })
    }
  }

  getErrorMessage(fieldName: string): string {
    const control = this.mfaLoginForm.get(fieldName);
    if (!control) {
      return '';
    }
    if (control.hasError('required')) {
      return this.translate.instant('mfaCredentialsPage.validationError.required');
    }
    if (control.hasError('email')) {
      return this.translate.instant('mfaCredentialsPage.validationError.invalidEmailFormat');
    }
    return '';
  }

  openDialog(): void {
    this.dialog.open(LoginPageMfaHelpComponent, {});
  }

  openSnackBar(message: string, type: SnackBarType) {
    const config = snackbarConfig(type);
    this.snackBar.open(message, null, config);
  }


}

import {FormControl} from '@angular/forms';
import * as moment from 'moment';
import 'moment-timezone';
import {Institution} from "./institution";

moment.tz.setDefault('UTC');

export class SearchFilter {
  public startDate = new FormControl(moment(null));
  public endDate = new FormControl(moment(null));
  public query: string;
  public minDate = moment(null);
  public maxDate = moment(null);
  public orderType: string = 'DATE';
  public pageSize: number;
  public totalResults: number;
  public institutions: Institution[] = [];
  public selectedInstitutionCode: string = '';
  public selectedRequesterDoctorCode: string = '';

}

import {UserInstitution} from './user-institution';
import {Role} from './role';
import {Institution} from './institution';
import {Status} from "./status";

export class User {
  id: number;
  firstName: string;
  lastName: string;
  idCode: string;
  doctorCode: string;
  userStatus: string;
  role: Role;
  mfaEnabled: boolean;
}

export class UserCreateUpdate {
  id: number;
  firstName: string;
  lastName: string;
  idCode: string;
  doctorCode: string;
  imported: boolean;
  userStatus: Status;
  associatedInstitutions: Institution[];
  grantedAccessRightsForResults: UserInstitution[];
  grantedAccessRightsForInvoiceExtra: Institution[];
  role: Role;
  email: string;
  password: string;
  phoneNumber: string;
  mfaEnabled: boolean;
}

export class UserDetails {
  id: number;
  firstName: string;
  lastName: string;
  idCode: string;
  doctorCode: string;
  userStatus: Status;
  associatedInstitutions: Institution[];
  grantedAccessRightsForResults: UserInstitution[];
  grantedAccessRightsForInvoiceExtra: Institution[];
  role: Role;
  email: string;
  phoneNumber: string;
  mfaEnabled: boolean;
}

export class Requester {
  userId: number
  firstName: string
  lastName: string
  doctorCode: string
}

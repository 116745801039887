import {MatSnackBarConfig} from '@angular/material/snack-bar';

export function snackbarConfig(type: SnackBarType) {
  const config = new MatSnackBarConfig();
  config.duration = 4000;
  config.verticalPosition = 'top';
  if (type === SnackBarType.error) {
    config.panelClass = ['snackbar-error'];
  } else if (type === SnackBarType.success) {
    config.panelClass = ['snackbar-success'];
  } else if (type === SnackBarType.loginFail) {
    config.panelClass = ['snackbar-login-fail'];
  }
  return config;
}

export enum SnackBarType {
  success,
  error,
  loginFail
}

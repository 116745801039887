import {Component, OnInit, ViewChild} from '@angular/core';
import {ResultService} from '../../services/result.service';
import {whiteGrayBackground} from '../../utils/table-utils';
import {SearchFilter} from '../../models/search-filter';
import {Result} from '../../models/result';
import {Page} from '../../models/page';
import {Patient} from '../../models/patient';
import {DeleteResultDialogComponent} from '../../components/delete-result-dialog/delete-result-dialog.component';
import {ResultFile} from '../../models/result-file';
import {AuthenticationService} from '../../services/authentication.service';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {snackbarConfig, SnackBarType} from '../../utils/snackbar-utils';
import {Institution} from '../../models/institution';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from "@ngx-translate/core";
import {
  RequesterFilterDropdownComponent
} from "../../components/requester-filter-dropdown/requester-filter-dropdown.component";
import {AccessRightsService} from "../../services/access-rights.service";

@Component({
  selector: 'app-results-overview',
  templateUrl: './results-overview.component.html',
  styleUrls: ['./results-overview.component.css']
})

export class ResultsOverviewComponent implements OnInit {
  public searchFilter: SearchFilter = new SearchFilter();
  private searchParams: SearchFilter = new SearchFilter();

  orderOptions: Order[];

  displayedColumns: string[] = ['date', 'createdAt', 'firstName', 'lastName', 'idCode', 'doctorCode', 'order', 'topic', 'openResult'];
  dataSource: MatTableDataSource<Result<Patient>>;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(RequesterFilterDropdownComponent) requesterFilterComponent: RequesterFilterDropdownComponent;

  constructor(private resultService: ResultService,
              private accessRightsService: AccessRightsService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private authenticationService: AuthenticationService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.initializeOrderOptions();
    this.translate.onLangChange.subscribe(() => {
      this.initializeOrderOptions();
    });
    this.resultService.getResults(this.params(this.searchParams, true))
      .subscribe((page: Page<Result<Patient>>) => {
        this.fillContent(page);
        this.watchPageChange();
      });
    this.getInstitutions();
    this.displayDelete();
  }

  search() {
    this.resultService.getResults(this.params(this.searchFilter, true))
      .subscribe((page: Page<Result<Patient>>) => {
        this.fillContent(page);
      });
    this.setSearchParams();
    this.getInstitutions();
  }

  watchPageChange() {
    this.paginator.page.subscribe(() => {
      this.resultService.getResults(this.params(this.searchParams)).subscribe((page: Page<Result<Patient>>) => {
        this.fillContent(page);
      });
    });
  }

  getInstitutions() {
    this.accessRightsService.getAccessibleInstitutionsForResults().subscribe((institutionList: any) => {
      this.searchFilter.institutions = [...institutionList];
    });
  }
  onRequesterSelection(selectedDoctorCode: string) {
    this.searchFilter.selectedRequesterDoctorCode = selectedDoctorCode;
  }

  displayInstNameOrCode(institution: Institution) {
    return institution.name ? institution.name : institution.code;
  }

  getMinDate(event) {
    this.searchFilter.minDate = event.value;
    return this.searchFilter.minDate;
  }

  getMaxDate(event) {
    this.searchFilter.maxDate = event.value;
    return this.searchFilter.maxDate;
  }

  changeDateFormat(date: string) {
    return moment(date).format('DD.MM.YYYY');
  }

  getPdf(id: number) {
    this.resultService.getResultPdf(id).subscribe({
      next: (res) => {
        this.openPdfInNewTab(res);

        const resultToUpdate = this.dataSource.data.find((result) => result.id === id);
        if (resultToUpdate && !this.authenticationService.isAdmin()) {
          resultToUpdate.opened = true;
        }

      },
      error: () => {
        this.openSnackBar(this.translate.instant('resultsPage.apiError.failedToOpenFile'), SnackBarType.error);
      }
    });
  }

  openPdfInNewTab(file: ResultFile) {
    const content = this.base64ToArrayBuffer(file.content);
    const filename = file.name + '.pdf';
    const blob = new Blob([content], {type: 'application/pdf'});

    if (window.navigator) {
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
      return;
    }

    const data = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }

  base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  displayDelete() {
    if (this.authenticationService.isAdmin()) {
      this.displayedColumns.push('deleteResult');
    }
  }

  private initializeOrderOptions() {
    this.translate.get([
      'resultsPage.orderBy.orderDate', 'resultsPage.orderBy.resultDate', 'resultsPage.orderBy.patient', 'resultsPage.orderBy.allInstitutions'
    ]).subscribe(translations => {
      this.orderOptions = [
        {value: 'DATE', viewValue: translations['resultsPage.orderBy.orderDate']},
        {value: 'CREATED_AT', viewValue: translations['resultsPage.orderBy.resultDate']},
        {value: 'PATIENT', viewValue: translations['resultsPage.orderBy.patient']},
      ];
    });
  }

  private fillContent(pageResult: Page<Result<Patient>>) {
    const {content, size, totalElements, pageNumber} = pageResult;
    this.dataSource = new MatTableDataSource(whiteGrayBackground(content, this.searchParams.orderType));
    this.searchFilter.pageSize = size;
    this.searchFilter.totalResults = totalElements;
    this.paginator.pageIndex = pageNumber;
  }

  private params(params: SearchFilter, newSearch = false) {
    const {orderType, query, startDate, endDate, selectedInstitutionCode, selectedRequesterDoctorCode} = params;
    const pageIndex: number = newSearch ? 0 : this.paginator.pageIndex;
    return {
      page: pageIndex,
      order: orderType,
      ...(selectedInstitutionCode) && {institution: selectedInstitutionCode},
      ...(selectedRequesterDoctorCode) && {requesterDoctorCode: selectedRequesterDoctorCode},
      ...(query) && {query},
      ...(startDate.value !== null && startDate.value.format() !== 'Invalid date') && {startDate: startDate.value.format()},
      ...(endDate.value !== null && endDate.value.format() !== 'Invalid date') && {endDate: endDate.value.format()},
    };
  }

  setSearchParams() {
    this.searchParams = {...this.searchFilter};
  }

  emptySearch() {
    this.requesterFilterComponent.resetField();
    this.searchFilter = new SearchFilter();
    this.search();
    this.searchParams.startDate = new FormControl(moment(null));
    this.searchParams.endDate = new FormControl(moment(null));
  }

  openDeleteResultDialog(result: Result<Patient>) {
    const dialogRef = this.dialog.open(DeleteResultDialogComponent, {
      data: result
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res.action === 'delete') {
        this.resultService.deleteResult(res.id).subscribe({
          next: () => {
            this.openSnackBar(this.translate.instant('resultsPage.messages.deletedSuccessfully'), SnackBarType.success);
            this.search();
          },
          error: () => {
            this.openSnackBar(this.translate.instant('resultsPage.apiError.failedToDeleteResult'), SnackBarType.error);
          }
        })
      }
    });
  }

  openSnackBar(message: string, success: SnackBarType) {
    const config = snackbarConfig(success);
    this.snackBar.open(message, null, config);
  }

  shortString(input: string) {
    return input.length > 25 ? `${input.substring(0, 25)}...` : input;
  }
}

export interface Order {
  value: string;
  viewValue: string;
}

import {Component} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {MatDialog} from "@angular/material/dialog";
import {PasswordChangeDialogComponent} from "../password-change-dialog/password-change-dialog.component";

@Component({
  selector: 'app-admin-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css']
})
export class HeaderMenuComponent {

  constructor(private authenticationService: AuthenticationService, public dialog: MatDialog) {}

  openDialog() {
    this.dialog.open(PasswordChangeDialogComponent, {autoFocus: false, maxHeight: '95vh'})
  }

  logout() {
    this.authenticationService.logoutAndFinishSession();
  }

  isAdmin() {
    return this.authenticationService.isAdmin();
  }

  isAdminOrAg() {
    return this.authenticationService.isAdminOrAg();
  }

  isMfaEnabled() {
    return this.authenticationService.isMfaEnabled();
  }

}

import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../models/page';
import {Injectable} from '@angular/core';
import {InvoiceExtra} from '../models/invoice-extra';
import {InvoiceExtraFile} from '../models/invoice-extra-file';

@Injectable({
  providedIn: 'root'
})

export class InvoiceExtrasService {

  private baseUrlInvoiceExtras = 'api/v1/invoice-extras';

  constructor(private httpClient: HttpClient) {
  }

  getInvoiceExtras(params: any): Observable<Page<InvoiceExtra>> {
    return this.httpClient.get<Page<InvoiceExtra>>(`${this.baseUrlInvoiceExtras}`, {params});
  }

  getInvoiceExtraPdf(id: number, payerId: string): Observable<InvoiceExtraFile> {
    const params = new HttpParams().set('institutionCode', payerId);

    return this.httpClient.get<InvoiceExtraFile>(`${this.baseUrlInvoiceExtras}/${id}/pdf`, {
      params: params,
      responseType: 'json'
    });
  }

  getInvoiceExtraXls(id: number, payerId: string) {
    const params = new HttpParams().set('institutionCode', payerId);
    return this.httpClient.get<InvoiceExtraFile>(`${this.baseUrlInvoiceExtras}/${id}/xls`, {
      params: params,
      responseType: 'json'
    });
  }

}

<div class="main-content">
  <div class="flex-container">
    <mat-card fxLayout="column" fxLayoutAlign="center center">
      <mat-card-title>{{'mfaCredentialsPage.title' | translate}}</mat-card-title>
      <mat-card-content>
        <form [formGroup]="mfaLoginForm" (ngSubmit)="submitCredentials()">
          <div>
            <mat-form-field>
              <mat-label>{{'mfaCredentialsPage.labels.email' | translate}}</mat-label>
              <input matInput type="email" maxlength="255" formControlName="email">
              <mat-error class="validation-error-message">
                {{ getErrorMessage('email') }}
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field>
              <mat-label>{{'mfaCredentialsPage.labels.password' | translate}}</mat-label>
              <input matInput [type]="hidePassword ? 'password' : 'text'" maxlength="255" formControlName="password">
              <mat-icon matSuffix
                        (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error class="validation-error-message">
                {{ getErrorMessage('password') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <button type="submit" [disabled]="isLoading" mat-raised-button color="primary"
                    class="login-button">{{ (isLoading ? 'common.buttons.loginLoading' : 'common.buttons.login') | translate }}
            </button>
          </div>
        </form>
      </mat-card-content>

    </mat-card>
    <p class="help-text"><a href="javascript:void(0)" (click)="openDialog()">{{'common.buttons.help' | translate}}</a>
    </p>

  </div>

</div>

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';

import {getPaginatorIntl} from 'src/app/utils/paginator-intl';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TranslateService} from "@ngx-translate/core";


@NgModule({
  imports: [],
  exports: [
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    MatDialogModule,
    MatGridListModule,
    MatSnackBarModule
  ],
  providers: [
    {provide: MatDatepickerModule},
    { provide: MAT_DATE_LOCALE, useValue: 'et-EE' },
    {
      provide: MatPaginatorIntl,
      useFactory: (translateService: TranslateService) => getPaginatorIntl(translateService),
      deps: [TranslateService]
    },
  ]
})

export class AngularMaterialModule {}

<div class="container">
  <h1 mat-dialog-title>{{ 'mfaLoginPageHelp.title' | translate }}</h1>
  <div mat-dialog-content>
    <p>{{ 'mfaLoginPageHelp.content.paragraph1' | translate }}</p>
    <ul>
      <li>{{ 'mfaLoginPageHelp.content.emailLabel' | translate }}: <a
        href="mailto:{{ 'mfaLoginPageHelp.contact.email' | translate }}">{{ 'mfaLoginPageHelp.contact.email' | translate }}</a>
      </li>
      <li>{{ 'mfaLoginPageHelp.content.phoneNumberLabel' | translate }}
        : {{ 'mfaLoginPageHelp.contact.phone' | translate }}</li>
    </ul>
  </div>
  <div mat-dialog-actions class="button">
    <button mat-stroked-button (click)="closeDialog()">{{ 'common.buttons.close' | translate }}</button>
  </div>
</div>


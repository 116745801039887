<div>
  <mat-form-field class="dropdown-list">
    <mat-label>{{ 'common.labels.selectInstitution' | translate }}</mat-label>
    <mat-select [formControl]="institutionCtrl" disableOptionCentering>
      <mat-option>
        <ngx-mat-select-search [formControl]="institutionFilterCtrl"
                               noEntriesFoundLabel="{{ 'common.labels.noEntriesFoundLabel' | translate }}"
                               placeholderLabel="{{ 'common.labels.searchPlaceholder' | translate }}">
        </ngx-mat-select-search>
      </mat-option>
      <mat-option [value]="''" *ngIf="!isAdmin()">{{ 'common.labels.allInstitutions' | translate }}</mat-option>
      <mat-option *ngFor="let institution of filteredInstitutions | async" [value]="institution">
        {{ displayInstitutionNameOrCode(institution)}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


<div class="main-content">

  <div fxLayout="row" fxLayoutAlign="start" class="warning" *ngIf="isAdmin()">
    <h2>{{ 'invoiceExtrasPage.info.message' | translate }}</h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px"
       fxLayout.lt-md="column" fxLayoutAlign.lt-md="center stretch" fxLayoutGap.lt-md="15px">
    <div fxFlex="15%">
      <mat-form-field>
        <label>
          <input [max]="searchFilter.maxDate" matInput (dateChange)="getMinDate($event)" [matDatepicker]="firstDate"
                 placeholder="{{ 'invoiceExtrasPage.labels.from' | translate }}"
                 [formControl]="searchFilter.startDate">
        </label>
        <mat-datepicker-toggle matSuffix [for]="firstDate"></mat-datepicker-toggle>
        <mat-datepicker #firstDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <label>
          <input [min]="searchFilter.minDate" matInput (dateChange)="getMaxDate($event)" [matDatepicker]="secondDate"
                 placeholder="{{ 'invoiceExtrasPage.labels.to' | translate }}"
                 [formControl]="searchFilter.endDate">
        </label>
        <mat-datepicker-toggle matSuffix [for]="secondDate"></mat-datepicker-toggle>
        <mat-datepicker #secondDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex="20%">
      <app-institution-dropdown
        [institutions]="allInstitutions ?? []"
        (selectionChange)="onInstitutionSelection($event)"></app-institution-dropdown>
    </div>

    <div fxFlex="50%" fxLayoutAlign.gt-sm="end end">
      <div fxLayoutAlign="space-between">
        <button (click)="emptySearch()" mat-stroked-button class="empty-search">{{ 'common.buttons.clear' | translate }}</button>
        <button (click)="search()" mat-raised-button class="search-button">{{'common.buttons.search' | translate}}</button>
      </div>
    </div>

  </div>

  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8">
    <ng-container matColumnDef="generationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'invoiceExtrasPage.tableHeaders.creationDate' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.generationDate | date:'dd.MM.yyyy HH:mm'}}</td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef>{{ 'invoiceExtrasPage.tableHeaders.analysisPeriod' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.periodStartDate | date:'dd.MM.yyyy'}}
        - {{element.periodEndDate | date:'dd.MM.yyyy'}}</td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>{{ 'invoiceExtrasPage.tableHeaders.institution' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{shortString(element.payerName)}}</td>
    </ng-container>

    <ng-container matColumnDef="openResult">
      <th class="openResultHeader" mat-header-cell *matHeaderCellDef>{{ 'invoiceExtrasPage.tableHeaders.open' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <a mat-button (click)="getPdf(element.id, element.payerId)">
          <mat-icon>picture_as_pdf</mat-icon>
        </a>
        <a mat-button (click)="getXls(element.id, element.payerId)">
          <mat-icon>description</mat-icon>
        </a>
      </td>

    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        [ngClass]="!element.white && 'make-light-blue'"></tr>
  </table>
  <mat-paginator [pageSize]='searchFilter.pageSize'
                 [length]='searchFilter.totalResults'
                 showFirstLastButtons></mat-paginator>

</div>

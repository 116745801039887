<div class="main-content">
  <div fxLayout="row" fxLayoutAlign="end">
    <mat-form-field class="search-bar" appearance="outline">
      <mat-label>{{ 'adminPage.labels.search' | translate }}</mat-label>
      <input matInput placeholder="" [(ngModel)]='searchQuery' (keydown.enter)="search()">
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-stroked-button class="add-user" (click)="openDialog()">{{ 'common.buttons.addUser' | translate }}</button>
    <button (click)="search()" mat-raised-button class="search-button">{{ 'common.buttons.search' | translate }}</button>
  </div>

  <table mat-table [dataSource]="dataSourceTable" class="mat-elevation-z8">

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>{{ 'adminPage.tableHeaders.firstName' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ shortString(element.firstName) }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>{{ 'adminPage.tableHeaders.lastName' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ shortString(element.lastName) }}</td>
    </ng-container>

    <ng-container matColumnDef="idCode">
      <th mat-header-cell *matHeaderCellDef>{{ 'adminPage.tableHeaders.idCode' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.idCode }}</td>
    </ng-container>

    <ng-container matColumnDef="doctorCode">
      <th mat-header-cell *matHeaderCellDef>{{ 'adminPage.tableHeaders.doctorCode' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.doctorCode }}</td>
    </ng-container>

    <ng-container matColumnDef="userStatus">
      <th mat-header-cell *matHeaderCellDef>{{ 'adminPage.tableHeaders.userStatus' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.userStatus === 'ACTIVE'">{{ 'adminPage.statuses.active' | translate }}</span>
        <span *ngIf="element.userStatus === 'INACTIVE'">{{ 'adminPage.statuses.inactive' | translate }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>{{ 'adminPage.tableHeaders.edit' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <button mat-button (click)="openDialog(element.id)">
          <mat-icon>create</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsAdmin"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumnsAdmin;"></tr>
  </table>
  <mat-paginator [pageSize]="50" showFirstLastButtons></mat-paginator>
</div>

import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Institution} from "../../models/institution";
import {FormControl} from "@angular/forms";
import {map, Observable, startWith, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-institution-dropdown',
  templateUrl: './institution-dropdown.component.html',
  styleUrls: ['./institution-dropdown.component.css']
})
export class InstitutionDropdownComponent implements OnInit, OnChanges, OnDestroy {
  @Input() institutions: Institution[] = [];
  @Output() selectionChange = new EventEmitter<Institution>();

  public institutionCtrl: FormControl = new FormControl();
  public institutionFilterCtrl: FormControl = new FormControl();
  public filteredInstitutions: Observable<Institution[]>;

  private onDestroy = new Subject<void>();

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.initializeFilter();
    this.institutionCtrl.setValue('');

    this.institutionCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((selectedInstitution) => {
      this.selectionChange.emit(selectedInstitution);
    });
  }

  initializeFilter() {
    this.filteredInstitutions = this.institutionFilterCtrl.valueChanges
      .pipe(
        startWith(''),
        map(search => this.filterInstitutions(search)),
      );
  }

  filterInstitutions(value: string): Institution[] {
    const filterValue = value.toLowerCase();
    return this.institutions.filter(institution =>
      institution.name?.toLowerCase().includes(filterValue)
    );
  }

  displayInstitutionNameOrCode(institution: Institution): string {
    return institution.name ? institution.name : institution.code;
  }

  isAdmin() {
    return this.authenticationService.isAdmin();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.institutions && !changes.institutions.isFirstChange()) {
      this.initializeFilter();
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  resetField(): void {
    this.institutionCtrl.setValue('');
  }

}

<div>
  <mat-form-field class="dropdown-list">
    <mat-label>{{ 'requestFilterDropdown.selectRequester' | translate }}</mat-label>
    <mat-select [formControl]="requesterCtrl" disableOptionCentering>
      <mat-option>
        <ngx-mat-select-search [formControl]="requesterFilterCtrl"
                               placeholderLabel="{{ 'common.labels.searchPlaceholder' | translate }}">
        </ngx-mat-select-search>
      </mat-option>
      <mat-option [value]="''">{{ 'requestFilterDropdown.allRequesters' | translate }}</mat-option>
      <mat-option *ngFor="let requester of filteredRequesters | async" [value]="requester.doctorCode">
        {{displayRequesterNameAndCode(requester)}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


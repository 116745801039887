import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, map, Observable, of, startWith, Subject, switchMap} from 'rxjs';
import {Institution} from '../../models/institution';
import {takeUntil} from 'rxjs/operators';
import {InstitutionService} from "../../services/institution.service";

@Component({
  selector: 'app-institutions-dropdown-multi',
  templateUrl: './institutions-dropdown-multi.component.html',
  styleUrls: ['./institutions-dropdown-multi.component.css']
})
export class InstitutionsDropdownMultiComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string = '';
  @Input() selectedInstitutions: Institution[] = [];
  @Output() selectionChange = new EventEmitter<Institution[]>();

  institutionMultiCtrl: FormControl = new FormControl();
  institutionMultiFilterCtrl: FormControl = new FormControl();
  filteredInstitutions: Observable<Institution[]>;
  isLoading: boolean = false;
  onDestroy = new Subject<void>();

  constructor(private institutionService: InstitutionService) {}

  ngOnInit() {
    this.initializeFilter();
    this.institutionMultiCtrl.setValue(this.selectedInstitutions);

    this.institutionMultiCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((selectedInstitutions) => {
      this.selectionChange.emit(selectedInstitutions);
      this.selectedInstitutions = [...selectedInstitutions]
    });
  }

  initializeFilter() {
    this.filteredInstitutions = this.institutionMultiFilterCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(250),
        switchMap(search => this.filterInstitutions(search)),
      );
  }

  filterInstitutions(searchQuery: string): Observable<Institution[]> {
    if (!searchQuery) {
      return of(this.selectedInstitutions);
    }

    this.isLoading = true;
    return this.institutionService.searchInstitutions(searchQuery).pipe(
      map(results => {
        this.isLoading = false;
        return results;
      }),
      startWith([])
    );
  }

  displayInstitutionName(institution?: Institution): string {
    return institution.name ? institution.name : '';
  }

  compareInstitutions(i1: Institution, i2: Institution): boolean {
    return i1 && i2 ? i1.id === i2.id : i1 === i2;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.institutions) {
      this.initializeFilter();
    }
    if (changes.selectedInstitutions) {
      this.institutionMultiCtrl.setValue(this.selectedInstitutions);
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}

import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-login-page-help',
  templateUrl: './login-page-help.component.html',
  styleUrls: ['./login-page-help.component.css']
})
export class LoginPageHelpComponent {

  constructor(
      public dialogRef: MatDialogRef<LoginPageHelpComponent>,
      ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}

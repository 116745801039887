<div class="main-content">
  <div class="flex-container">

    <mat-card fxLayout="column" fxLayoutAlign="center center">
      <mat-card-title>{{'mfaPinVerificationPage.title' | translate}}</mat-card-title>
      <p>{{'mfaPinVerificationPage.instruction' | translate}}</p>
      <mat-card-content>
        <form [formGroup]="mfaVerificationForm" (ngSubmit)="submitPinVerification()">
          <div>
            <mat-form-field>
              <mat-label>{{'mfaPinVerificationPage.pinLabel' | translate}}</mat-label>
              <input matInput #pinCodeInput type="text" maxlength="4" formControlName="pinCode">
              <mat-error class="validation-error-message">
                {{ getErrorMessage('pinCode') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <button type="submit" [disabled]="isLoading" mat-raised-button color="primary"
                    class="verify-button">{{ (isLoading ? 'common.buttons.verifyLoading' : 'common.buttons.verify') | translate }}
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
    <p class="help-text"><a href="javascript:void(0)" (click)="openDialog()">{{'common.buttons.help' | translate}}</a></p>
  </div>

</div>

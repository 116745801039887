export const AppRoutes = {
  Home: '',
  LoginFailure: 'login/failure',
  MfaHome: 'eng',
  MfaCredentials: 'login/mfa-credentials',
  MfaPinVerification: 'login/mfa-pin-verification',
  Results: 'results',
  InvoiceExtras: 'invoice-extras',
  Admin: 'admin',
};

export const headerRoutes = [
  AppRoutes.Results,
  AppRoutes.Admin,
  AppRoutes.InvoiceExtras,
  AppRoutes.Home,
  AppRoutes.MfaHome,
  AppRoutes.MfaCredentials,
  AppRoutes.MfaPinVerification,
  AppRoutes.LoginFailure
];

export const containerRoutes = [
  AppRoutes.Results,
  AppRoutes.Admin,
  AppRoutes.InvoiceExtras
];

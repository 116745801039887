import {Injectable, NgZone} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {Router} from '@angular/router';

const MINUTES_UNTIL_LOGOUT = 120;
const CHECK_INTERVAL = 1000;

@Injectable({
  providedIn: 'root'
})
export class SessionMonitoringService {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  private loginTime: number;
  private isChecking = false;

  initListener(loginTime: number) {
    this.isChecking = true;
    this.loginTime = loginTime;
    this.initInterval();
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      const interval = setInterval(() => {
        if (!this.isChecking) {
          clearInterval(interval);
        }
        this.check();
      }, CHECK_INTERVAL);
    });
  }

  check() {
    if (this.isChecking) {
      const timeLeft = this.loginTime + MINUTES_UNTIL_LOGOUT * 60 * 1000;
      const isTimeout = timeLeft - Date.now() < 0;

      this.ngZone.run(() => {
        if (this.isChecking) {
          if (isTimeout && this.authenticationService.authenticatedUserValue) {
            this.isChecking = false;
            this.authenticationService.logout('session timeout');
          }
        }
      });
    }
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginPageComponent} from './pages/home-page/login-page.component';
import {ResultsOverviewComponent} from './pages/results-overview/results-overview.component';
import {InvoiceExtrasComponent} from './pages/invoice-extras/invoice-extras.component';
import {UserAdminPageComponent} from './pages/user-admin-page/user-admin-page-component';
import {AuthGuard} from './helpers/auth.guard';
import {Role} from './models/role';
import {LoginFailureComponent} from './pages/login-failure/login-failure.component';
import {LoginPageMfaComponent} from "./pages/home-page-mfa/login-page-mfa.component";
import {LoginPageMfaCredentialsComponent} from "./pages/login-page-mfa-credentials/login-page-mfa-credentials.component";
import {LoginPageMfaVerificationComponent} from "./pages/login-page-mfa-verification/login-page-mfa-verification.component";
import {AppRoutes} from "./utils/app-routes";

const routes: Routes = [
  { path: AppRoutes.Home, component: LoginPageComponent},
  { path: AppRoutes.LoginFailure, component: LoginFailureComponent},
  { path: AppRoutes.MfaHome, component: LoginPageMfaComponent},
  { path: AppRoutes.MfaCredentials, component: LoginPageMfaCredentialsComponent},
  { path: AppRoutes.MfaPinVerification, component: LoginPageMfaVerificationComponent},
  { path: AppRoutes.Results, component: ResultsOverviewComponent, canActivate: [AuthGuard], data: {roles: [Role.User, Role.Admin, Role.AG]}},
  { path: AppRoutes.InvoiceExtras, component: InvoiceExtrasComponent, canActivate: [AuthGuard], data: {roles: [Role.Admin, Role.AG]}},
  { path: AppRoutes.Admin, component: UserAdminPageComponent, canActivate: [AuthGuard], data: {roles: [Role.Admin]}},

  { path: '**', redirectTo: AppRoutes.Home}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

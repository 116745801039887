import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DateAdapter} from "@angular/material/core";

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent {

  constructor(private translate: TranslateService, private dateAdapter: DateAdapter<any>) {
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('userLanguage', language);
    if (language === 'et') {
      this.estonianLocale();
    } else {
      this.englishLocale();
    }
  }

  estonianLocale() {
    this.dateAdapter.setLocale('et-EE');
  }

  englishLocale() {
    this.dateAdapter.setLocale('en-US');
  }
}

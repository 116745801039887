import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularMaterialModule} from './angular-material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {FlexLayoutModule} from '@angular/flex-layout';

import {AppComponent} from './app.component';
import {LoginPageComponent} from './pages/home-page/login-page.component';
import {LoginPageHelpComponent} from './components/login-page-help/login-page-help.component';
import {HeaderComponent} from './components/header/header.component';
import {ResultsOverviewComponent} from './pages/results-overview/results-overview.component';
import {UserAdminPageComponent} from './pages/user-admin-page/user-admin-page-component';
import {
  CreateOrUpdateUserDialogComponent
} from './components/create-or-update-user-dialog/create-or-update-user-dialog-component';
import {UserInstitutionDropdown} from './components/user-institution-dropdown/user-institution-dropdown.component';
import {DeleteResultDialogComponent} from './components/delete-result-dialog/delete-result-dialog.component';
import {ErrorInterceptor} from './helpers/error.interceptor';
import {JwtInterceptor} from './helpers/jwt.interceptor';
import {HeaderMenuComponent} from './components/header-menu/header-menu.component';
import {InvoiceExtrasComponent} from './pages/invoice-extras/invoice-extras.component';
import {MatSortModule} from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
  InstitutionsDropdownMultiComponent
} from './components/institutions-dropdown-multi/institutions-dropdown-multi.component';
import {LoginFailureComponent} from './pages/login-failure/login-failure.component';
import {MatListModule} from '@angular/material/list';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {JWT_OPTIONS, JwtHelperService} from '@auth0/angular-jwt';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {PasswordChangeDialogComponent} from './components/password-change-dialog/password-change-dialog.component';
import {LoginPageMfaComponent} from './pages/home-page-mfa/login-page-mfa.component';
import {LoginPageMfaHelpComponent} from './components/login-page-mfa-help/login-page-mfa-help.component';
import {
  LoginPageMfaCredentialsComponent
} from './pages/login-page-mfa-credentials/login-page-mfa-credentials.component';
import {
  LoginPageMfaVerificationComponent
} from './pages/login-page-mfa-verification/login-page-mfa-verification.component';
import {NgOptimizedImage} from "@angular/common";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatCardModule} from "@angular/material/card";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {LanguageSelectorComponent} from './components/language-selector/language-selector.component';
import {
  RequesterFilterDropdownComponent
} from './components/requester-filter-dropdown/requester-filter-dropdown.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {InstitutionDropdownComponent} from './components/institution-dropdown/institution-dropdown.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    LoginPageHelpComponent,
    HeaderComponent,
    ResultsOverviewComponent,
    InvoiceExtrasComponent,
    UserAdminPageComponent,
    CreateOrUpdateUserDialogComponent,
    UserInstitutionDropdown,
    DeleteResultDialogComponent,
    HeaderMenuComponent,
    InstitutionsDropdownMultiComponent,
    LoginFailureComponent,
    PasswordChangeDialogComponent,
    LoginPageMfaComponent,
    LoginPageMfaHelpComponent,
    LoginPageMfaCredentialsComponent,
    LoginPageMfaVerificationComponent,
    LanguageSelectorComponent,
    RequesterFilterDropdownComponent,
    InstitutionDropdownComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatListModule,
    MatSortModule,
    MatTooltipModule,
    MatCheckboxModule,
    NgOptimizedImage,
    MatToolbarModule,
    MatCardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatProgressSpinnerModule
  ],
  providers: [
    // {provide: DateAdapter, useClass: MyDateAdapter},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS}, JwtHelperService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [CreateOrUpdateUserDialogComponent, LoginPageHelpComponent, DeleteResultDialogComponent]
})
export class AppModule {
}

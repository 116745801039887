<div fxLayoutAlign="center center" *ngIf="isEditMode && (!userDetails); else formTemplate">
  <mat-spinner diameter="50"></mat-spinner>
</div>

<ng-template #formTemplate>

  <form [formGroup]="userForm" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" class="dialog-container"
        *ngIf="userDetails">

    <div fxLayoutAlign="space-between center">
      <h2>{{ (isEditMode ? 'createOrUpdateUserDialog.pageTitleEdit' : 'createOrUpdateUserDialog.pageTitleCreate') | translate }}</h2>
    </div>

    <div fxLayoutAlign="space-between center" fxLayoutGap="24px">
      <mat-form-field>
        <mat-label>{{ 'createOrUpdateUserDialog.labels.firstName' | translate }}</mat-label>
        <input matInput type="text" maxlength="255" formControlName="firstName">
        <mat-error class="validation-error-message" *ngIf="isFieldInvalid('firstName')">
          {{ getErrorMessage('firstName') }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'createOrUpdateUserDialog.labels.lastName' | translate }}</mat-label>
        <input matInput type="text" maxlength="255" formControlName="lastName">
        <mat-error class="validation-error-message" *ngIf="isFieldInvalid('lastName')">
          {{ getErrorMessage('lastName') }}</mat-error>
      </mat-form-field>
    </div>

    <div fxLayoutAlign="space-between center" fxLayoutGap="24px">
      <mat-form-field>
        <mat-label>{{ 'createOrUpdateUserDialog.labels.idCode' | translate }}</mat-label>
        <input matInput type="text" maxlength="11" formControlName="idCode">
        <mat-error class="validation-error-message" *ngIf="isFieldInvalid('idCode')">
          {{ getErrorMessage('idCode') }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'createOrUpdateUserDialog.labels.doctorCode' | translate }}</mat-label>
        <input matInput maxlength="20" formControlName="doctorCode" [required]="userRoleNotAg()">
        <mat-error class="validation-error-message" *ngIf="isFieldInvalid('doctorCode')">
          {{ getErrorMessage('doctorCode') }}</mat-error>
      </mat-form-field>
    </div>

    <mat-checkbox
      formControlName="mfaEnabled">{{ 'createOrUpdateUserDialog.labels.mfaEnabled' | translate }}</mat-checkbox>

    <div *ngIf="userForm.get('mfaEnabled').value" class="mfa-fields">
      <div fxLayoutAlign="space-between center" fxLayoutGap="24px">
        <mat-form-field>
          <mat-label>{{ 'createOrUpdateUserDialog.labels.email' | translate }}</mat-label>
          <input matInput type="text" maxlength="100" formControlName="email">
          <mat-error class="validation-error-message" *ngIf="isFieldInvalid('email')">
            {{ getErrorMessage('email') }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'createOrUpdateUserDialog.labels.phoneNumber' | translate }}</mat-label>
          <input matInput type="text" maxlength="19" formControlName="phoneNumber">
          <mat-error class="validation-error-message" *ngIf="isFieldInvalid('phoneNumber')">
            {{ getErrorMessage('phoneNumber') }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayoutAlign="space-between center" fxLayoutGap="24px">
        <mat-form-field fxFlex="50%">
          <mat-label>{{ 'createOrUpdateUserDialog.labels.password' | translate }}</mat-label>
          <input matInput [type]="hidePassword ? 'password' : 'text'" maxlength="255" formControlName="password">
          <mat-icon matSuffix
                    (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error class="validation-error-message" *ngIf="isFieldInvalid('password')">
            {{ getErrorMessage('password') }}
          </mat-error>
        </mat-form-field>
        <div fxFlex="50%"></div>
      </div>

    </div>

    <div fxLayoutAlign="space-between center" fxLayoutGap="24px">
      <app-institutions-dropdown-multi
        [selectedInstitutions]="userDetails?.associatedInstitutions ?? []"
        label="{{ 'createOrUpdateUserDialog.labels.associatedInstitutions' | translate }}"
        (selectionChange)="onAssociatedInstitutionsSelected($event)">
      </app-institutions-dropdown-multi>
      <mat-form-field>
        <mat-label>{{ 'createOrUpdateUserDialog.labels.status' | translate }}</mat-label>
        <mat-select formControlName="userStatus">
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{ 'common.statuses.' + status.toLowerCase() | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayoutAlign="space-between start" fxLayoutGap="24px">
      <mat-list fxFlex="50%">
        <mat-list-item *ngFor="let institution of userForm.get('associatedInstitutions').value">
          {{displayInstitutionName(institution)}}
        </mat-list-item>
      </mat-list>
    </div>

    <div fxLayoutAlign="space-between start">
      <h3>{{'createOrUpdateUserDialog.pageSubtitleAccessRights' | translate }}</h3>
    </div>

    <div fxLayoutAlign="space-between" fxLayoutGap="24px">
      <app-user-institution-dropdown
        label="{{ 'createOrUpdateUserDialog.labels.grantedAccessRightsForResults' | translate }}"
        [userId]="userDetails?.id ?? null"
        [selectedUserInstitutions]="userDetails?.grantedAccessRightsForResults ?? []"
        (selectionChange)="onResultsAccessRightsSelection($event)">
      </app-user-institution-dropdown>

      <app-institutions-dropdown-multi
        label="{{ 'createOrUpdateUserDialog.labels.grantedAccessRightsForInvoiceExtra' | translate }}"
        [selectedInstitutions]="userDetails?.grantedAccessRightsForInvoiceExtra ?? []"
        (selectionChange)="onInvoiceExtraAccessRightsSelection($event)">
      </app-institutions-dropdown-multi>
    </div>

    <div fxLayoutAlign="space-between start" fxLayoutGap="24px">
      <mat-list fxFlex="50%">
        <mat-list-item *ngFor="let userInstitution of userForm.get('grantedAccessRightsForResults').value">
          {{displayDoctorCodeWithInstitution(userInstitution)}}
        </mat-list-item>
      </mat-list>

      <mat-list fxFlex="50%">
        <mat-list-item *ngFor="let institution of userForm.get('grantedAccessRightsForInvoiceExtra').value">
          {{ displayInstitutionName(institution)}}
        </mat-list-item>
      </mat-list>
    </div>


    <div fxLayoutAlign="end" class="button-container">
      <button (click)="closeDialog()" mat-stroked-button mat-dialog-close
              class="cancel-button">{{ 'common.buttons.cancel' | translate }}</button>
      <button type="submit" [disabled]="isDisabled()" mat-raised-button color="primary"
              class="save-button">{{ 'common.buttons.save' | translate }}
      </button>
    </div>

  </form>
</ng-template>

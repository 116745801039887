import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchFilter} from '../../models/search-filter';
import {InvoiceExtrasService} from '../../services/invoice-extras.service';
import {Page} from '../../models/page';
import {MatPaginator} from '@angular/material/paginator';
import {InvoiceExtra} from '../../models/invoice-extra';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {snackbarConfig, SnackBarType} from '../../utils/snackbar-utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InvoiceExtraFile} from '../../models/invoice-extra-file';
import {validateQueryDate} from '../../utils/date-utils';
import {MatSort} from '@angular/material/sort';
import {TranslateService} from "@ngx-translate/core";
import {FormControl} from "@angular/forms";
import * as moment from "moment/moment";
import {Institution} from "../../models/institution";
import {AccessRightsService} from "../../services/access-rights.service";
import {InstitutionDropdownComponent} from "../../components/institution-dropdown/institution-dropdown.component";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-invoice-extras',
  templateUrl: './invoice-extras.component.html',
  styleUrls: ['./invoice-extras.component.css']
})

export class InvoiceExtrasComponent implements OnInit {
  public searchFilter: SearchFilter = new SearchFilter();
  private searchParams: SearchFilter = new SearchFilter();

  allInstitutions: Institution[];

  displayedColumns: string[] = ['generationDate', 'period', 'client', 'openResult'];
  dataSource: MatTableDataSource<InvoiceExtra>;

  sortBy: string;
  sortOrder: string;

  @ViewChild(InstitutionDropdownComponent) institutionDropdown: InstitutionDropdownComponent;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, {static: true}) table: MatTable<InvoiceExtra>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private invoiceExtrasService: InvoiceExtrasService,
    private accessRightsService: AccessRightsService,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.loadContent();
    this.getInstitutions();
    this.watchPageChange();
  }

  loadContent() {
    this.invoiceExtrasService.getInvoiceExtras(this.params(this.searchFilter, true))
      .subscribe((page: Page<InvoiceExtra>) => {
        this.fillContent(page);
      });
  }

  getInstitutions() {
    this.accessRightsService.getAccessibleInstitutionsForInvoiceExtras().subscribe((institutionList: any) => {
      this.allInstitutions = institutionList;
    });
  }

  search() {
    this.loadContent();
    this.setSearchParams();
  }

  watchPageChange() {
    this.paginator.page.subscribe(() => {
      this.invoiceExtrasService.getInvoiceExtras(this.params(this.searchParams))
        .subscribe((page: Page<InvoiceExtra>) => {
          this.fillContent(page);
        });
    });
  }

  getMinDate(event) {
    return this.searchFilter.minDate = event.value;
  }

  getMaxDate(event) {
    return this.searchFilter.maxDate = event.value;
  }

  onInstitutionSelection(selectedInstitution: Institution) {
    this.searchFilter.selectedInstitutionCode = selectedInstitution.code;
  }

  private fillContent(pageResult) {
    const {content, size, totalElements, page} = pageResult;
    this.dataSource = new MatTableDataSource(content);
    this.searchFilter.pageSize = size;
    this.searchFilter.totalResults = totalElements;
    this.paginator.pageIndex = page;
  }

  private params(params: SearchFilter, newSearch = false) {
    const {query, startDate, endDate, selectedInstitutionCode} = params;

    const pageIndex: number = newSearch ? 0 : this.paginator.pageIndex;
    const sortBy: string = this.sortBy ? this.sortBy : 'generationDate';
    const sortOrder: string = this.sortOrder ? this.sortOrder : 'desc';
    return {
      page: pageIndex,
      sortBy,
      sortOrder,
      ...(query) && {query},
      ...validateQueryDate(startDate) && {startDate: startDate.value.format()},
      ...validateQueryDate(endDate) && {endDate: endDate.value.format()},
      ...(selectedInstitutionCode) && {institutionCode: selectedInstitutionCode},
    };
  }

  setSearchParams() {
    this.searchParams = {...this.searchFilter};
  }

  emptySearch() {
    this.institutionDropdown.resetField();
    this.searchFilter = new SearchFilter();
    this.search();
    this.searchParams.startDate = new FormControl(moment(null));
    this.searchParams.endDate = new FormControl(moment(null));
  }

  sortData($event) {
    this.sortBy = $event.active;
    this.sortOrder = $event.direction;
    this.search();
  }

  getPdf(id: number, payerId: string) {
    const fileType = 'application/pdf';
    this.invoiceExtrasService.getInvoiceExtraPdf(id, payerId).subscribe({
      next: (res) => this.openFile(res, fileType),
      error: (err) => this.openSnackBar(this.translate.instant('invoiceExtrasPage.errorMessages.failedToOpenFile'), SnackBarType.error)
      });
  }

  getXls(id: number, payerId: string) {
    const fileType = 'application/vnd.ms-excel';
    this.invoiceExtrasService.getInvoiceExtraXls(id, payerId).subscribe({
        next: (res) => this.openFile(res, fileType),
        error: (err) => this.openSnackBar(this.translate.instant('invoiceExtrasPage.errorMessages.failedToOpenFile'), SnackBarType.error)
      });
  }

  openFile(file: InvoiceExtraFile, fileType: string) {
    const content = this.base64ToArrayBuffer(file.content);
    const filename = file.name;
    const blob = new Blob([content], {type: fileType});

    const data = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }

  base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  isAdmin() {
    return this.authenticationService.isAdmin();
  }

  openSnackBar(message: string, success: SnackBarType) {
    const config = snackbarConfig(success);
    this.snackBar.open(message, null, config);
  }

  shortString(input: string) {
    return input.length > 25 ? `${input.substring(0, 25)}...` : input;
  }

}

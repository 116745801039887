import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-result-dialog',
  templateUrl: './delete-result-dialog.component.html',
  styleUrls: ['./delete-result-dialog.component.css']
})
export class DeleteResultDialogComponent {
  localData: any;

  constructor(
    public dialogRef: MatDialogRef<DeleteResultDialogComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.localData = {...data}
  }

  closeDialogAndPostResult() {
    this.dialogRef.close({action: 'delete', id: this.localData.id})
  }

  closeDialogWithoutPost() {
    this.dialogRef.close({action: 'cancel'})
  }
}

/**
 * same day dates are same color starting with white being a first date
 */
import {Result} from "../models/result";
import {Patient} from "../models/patient";

export function whiteGrayBackground(content: Result<Patient>[], orderType: string) {
  if (!content && !content.length) {
    return [];
  } else {
    let colorChangeCount = 1;
    for (let i = 0; i < content.length; i++) {
      let currentResult: string;
      let previousResult: string;

      if (i === 0) {
        content[i].white = true;
        continue;
      }

      if (orderType === 'PATIENT') {
        currentResult = content[i].patient.idCode;
        previousResult = content[i - 1].patient.idCode;
      } else if (orderType === 'CREATED_AT') {
        currentResult = content[i].createdAt;
        previousResult = content[i - 1].createdAt;
      } else {
        currentResult = content[i].orderDate;
        previousResult = content[i - 1].orderDate;
      }

      if (colorChangeCount % 2 !== 0 && currentResult === previousResult) {
        content[i].white = true;

      } else if (currentResult !== previousResult) {
        colorChangeCount += 1;
        if (colorChangeCount % 2 !== 0) {
          content[i].white = true;
        }
      }
    }
    return content;
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AccessRightsService {
  private baseUrl = 'api/v1/user/rights';
  constructor(private httpClient: HttpClient) {}

  getAccessibleUsersForResults() {
    return this.httpClient.get(`${this.baseUrl}/results/accessible-users`);
  }
  getAccessibleInstitutionsForResults() {
    return this.httpClient.get(`${this.baseUrl}/results/accessible-institutions`);
  }

  getAccessibleInstitutionsForInvoiceExtras() {
    return this.httpClient.get(`${this.baseUrl}/invoice-extras/accessible-institutions`);
  }

}

<form [formGroup]="passwordForm" (ngSubmit)="changePassword()" (keydown.enter)="$event.preventDefault()"
      class="dialog-container">
  <h2>{{ 'passwordChangeDialog.title' | translate }}</h2>
  <div>
    <mat-form-field>
      <mat-label>{{ 'passwordChangeDialog.labels.oldPassword' | translate }}</mat-label>
      <input matInput [type]="hideOldPassword ? 'password' : 'text'" maxlength="255" formControlName="oldPassword">
      <mat-icon matSuffix
                (click)="hideOldPassword = !hideOldPassword">{{hideOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error class="validation-error-message">
        {{ getErrorMessage('oldPassword') }}
      </mat-error>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>{{ 'passwordChangeDialog.labels.newPassword' | translate }}</mat-label>
      <input matInput [type]="hideNewPassword ? 'password' : 'text'" maxlength="255" formControlName="newPassword">
      <mat-icon matSuffix
                (click)="hideNewPassword = !hideNewPassword">{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error class="validation-error-message">
        {{ getErrorMessage('newPassword') }}
      </mat-error>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>{{ 'passwordChangeDialog.labels.confirmNewPassword' | translate }}</mat-label>
      <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" maxlength="255"
             formControlName="confirmPassword">
      <mat-icon matSuffix
                (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error class="validation-error-message">
        {{ getErrorMessage('confirmPassword') }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="button-container">
    <button
      (click)="closeDialog()" mat-stroked-button mat-dialog-close>{{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      type="submit" [disabled]="!passwordForm.valid" mat-raised-button color="primary" mat-dialog-close
      class="save-button">{{ 'common.buttons.save' | translate}}
    </button>
  </div>

</form>
import {Component, OnInit, ViewChild} from '@angular/core';
import {
  CreateOrUpdateUserDialogComponent
} from '../../components/create-or-update-user-dialog/create-or-update-user-dialog-component';
import {User} from '../../models/user';
import {UserService} from '../../services/user.service';
import {Page} from '../../models/page';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-user-admin-page-component',
  templateUrl: './user-admin-page-component.html',
  styleUrls: ['./user-admin-page-component.css']
})

export class UserAdminPageComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, {static: true}) table: MatTable<User>;
  public searchQuery: string;
  dataSourceTable = new MatTableDataSource<User>();
  displayedColumnsAdmin: string[] = ['firstName', 'lastName', 'idCode', 'doctorCode', 'userStatus', 'edit'];
  constructor(
    private dialog: MatDialog,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.fetchUsers(true);
    this.paginator.page.subscribe(() => {
      this.fetchUsers();
    })
  }

  search() {
    this.fetchUsers(true);
  }

  openDialog(userId: string = null) {
    const dialogRef = this.dialog.open(CreateOrUpdateUserDialogComponent, {
      data: { userId },
      autoFocus: false,
      maxHeight: '90vh',
      width: '50vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.success) {
        this.fetchUsers(true);
      }
    });
  }

  fetchUsers(resetPagination: boolean = false) {
    if (resetPagination) {
      this.paginator.pageIndex = 0;
    }
    this.userService.getUsers({
      ...(this.searchQuery) && { query: this.searchQuery },
      page: this.paginator.pageIndex,
    }).subscribe((page: Page<User>) => this.fillContent(page));
  }

  fillContent(page: Page<User>) {
    this.dataSourceTable.data = page.content;
    this.paginator.pageIndex = page.pageNumber;
    this.paginator.pageSize = page.size;
    this.paginator.length = page.totalElements;
  }

  shortString(input: string): string {
    const parsedInput = input?.length > 25 ? `${input.substring(0, 25)}...` : input;
    return input ? parsedInput : null;
  }
}

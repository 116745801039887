const EXTENDED_CHARS_LOWER = 'a-zà-öø-ÿā-ž';
const EXTENDED_CHARS_UPPER = 'A-ZÀ-ÖØ-ŸĀ-Ž';
const SPECIAL_CHARS = '!@#\\$%\\^&\\*\\(\\)_\\+\\{\\}:"<>\\?~`\\|\\\\,./;\'\\[\\]\\-';

export const ID_CODE_PATTERN = '^[0-9]{11}$';
export const ID_CODE_PATTERN_MFA = '^[0-9a-zA-Z]{11}$';
export const DOCTOR_CODE_PATTERN = '^[0-9a-zA-Z]{0,20}$';
export const NAME_PATTERN = '^[' + EXTENDED_CHARS_LOWER +  EXTENDED_CHARS_UPPER + '\' -]{0,255}$';
export const EMAIL_PATTERN = '^[a-zA-Z0-9._%+-]{1,50}@[a-zA-Z0-9.-]{1,50}\\.[a-zA-Z]{2,6}$';
export const PHONE_NUMBER_PATTERN = '^\\+[0-9]{1,3}[0-9]{1,15}$';
export const PASSWORD_PATTERN = '^(?=.*[0-9])(?=.*[' +
  EXTENDED_CHARS_LOWER + '])(?=.*[' + EXTENDED_CHARS_UPPER + '])(?=.*[' + SPECIAL_CHARS + ']).{10,255}$';

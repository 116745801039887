import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserDetails} from '../../models/user';
import {Institution} from '../../models/institution';
import {UserInstitution} from '../../models/user-institution';
import {Role} from '../../models/role';
import {Status} from "../../models/status";
import {
  DOCTOR_CODE_PATTERN,
  EMAIL_PATTERN,
  ID_CODE_PATTERN,
  ID_CODE_PATTERN_MFA,
  NAME_PATTERN,
  PASSWORD_PATTERN,
  PHONE_NUMBER_PATTERN
} from "../../utils/regex-patterns";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../services/user.service";
import {InstitutionService} from "../../services/institution.service";
import {DOCTOR_CODE_IN_USE, EMAIL_IN_USE, ID_CODE_IN_USE, PHONE_NUMBER_IN_USE} from "../../utils/error-messages";
import {snackbarConfig, SnackBarType} from "../../utils/snackbar-utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './create-or-update-user-dialog-component.html',
  styleUrls: ['./create-or-update-user-dialog-component.css']
})

export class CreateOrUpdateUserDialogComponent implements OnInit, OnDestroy {
  isEditMode: boolean = false
  hidePassword: boolean = true;
  userForm: FormGroup;

  userDetails: UserDetails;
  statuses: Status[] = [Status.Active, Status.Inactive];
  isLoading: boolean = false;
  private onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CreateOrUpdateUserDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private institutionService: InstitutionService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.isEditMode = !!this.data.userId;

    if (this.isEditMode) {
      this.loadAndInitializeUserData();
    } else {
      this.userDetails = new UserDetails();
      this.initializeFormControls();
    }
  }

  private loadAndInitializeUserData() {
    this.userService.getUserById(this.data.userId).subscribe(user => {
      this.userDetails = user;
      this.initializeFormControls();
    });
  }

  private initializeFormControls() {
    this.userForm = new FormGroup({
      idCode: new FormControl({
          value: this.userDetails.idCode || '',
          disabled: this.isEditMode && !!this.userDetails.idCode
        },
        [Validators.required, Validators.pattern(ID_CODE_PATTERN)]),
      doctorCode: new FormControl(this.userDetails.doctorCode || '', [Validators.pattern(DOCTOR_CODE_PATTERN)]),
      firstName: new FormControl(this.userDetails.firstName || '', [Validators.required, Validators.pattern(NAME_PATTERN)]),
      lastName: new FormControl(this.userDetails.lastName || '', [Validators.required, Validators.pattern(NAME_PATTERN)]),
      role: new FormControl(this.userDetails.role || Role.User),
      userStatus: new FormControl(this.userDetails.userStatus || Status.Active),
      associatedInstitutions: new FormControl(this.userDetails.associatedInstitutions || []),
      grantedAccessRightsForResults: new FormControl(this.userDetails.grantedAccessRightsForResults || []),
      grantedAccessRightsForInvoiceExtra: new FormControl(this.userDetails.grantedAccessRightsForInvoiceExtra || []),
      mfaEnabled: new FormControl({value: this.userDetails.mfaEnabled || false, disabled: this.isEditMode}),
      email: new FormControl(this.userDetails.email || '', [Validators.pattern(EMAIL_PATTERN)]),
      phoneNumber: new FormControl(this.userDetails.phoneNumber || '', [Validators.pattern(PHONE_NUMBER_PATTERN)]),
      password: new FormControl('', [Validators.pattern(PASSWORD_PATTERN)]),
    })
    if (this.isEditMode) {
      this.updateUserValidators(this.userDetails.mfaEnabled);
    }
    this.subscribeToMfaChanges();
  }

  private subscribeToMfaChanges() {
    this.userForm.get('mfaEnabled').valueChanges.pipe(
      takeUntil(this.onDestroy)
    ).subscribe(isMfaUser => {
      this.updateUserValidators(isMfaUser);
    });
  }

  private setUserValidator(controlName: string, pattern: string, isRequired: boolean = true, toReset: boolean = true): void {
    const control = this.userForm.get(controlName);
    const validators = [Validators.pattern(pattern)];
    if (isRequired) {
      validators.push(Validators.required);
    }
    control.setValidators(validators);
    if (toReset) {
      control.reset();
    }
    control.updateValueAndValidity();
  }

  private updateUserValidators(isMfaUser: boolean): void {
    this.setUserValidator('idCode', isMfaUser ? ID_CODE_PATTERN_MFA : ID_CODE_PATTERN, true, false);
    this.setUserValidator('email', EMAIL_PATTERN, isMfaUser, !isMfaUser);
    this.setUserValidator('phoneNumber', PHONE_NUMBER_PATTERN, isMfaUser, !isMfaUser);
    this.setUserValidator('password', PASSWORD_PATTERN, isMfaUser && !this.isEditMode, !isMfaUser);
  }

  onAssociatedInstitutionsSelected(selectedInstitutions: Institution[]) {
    this.userForm.get('associatedInstitutions').setValue(selectedInstitutions);
  }

  onResultsAccessRightsSelection(selectedUserInstitutions: UserInstitution[]) {
    this.userForm.get('grantedAccessRightsForResults').setValue(selectedUserInstitutions);
  }

  onInvoiceExtraAccessRightsSelection(selectedInstitutions: Institution[]) {
    this.userForm.get('grantedAccessRightsForInvoiceExtra').setValue(selectedInstitutions);
    const newRole = selectedInstitutions.length > 0 ? Role.AG : Role.User;
    this.userForm.get('role').setValue(newRole);
  }

  save() {
    this.isLoading = true;
    if (this.userForm.valid) {
      const userPayload = {...this.userDetails, ...(this.userForm.value)}

      const saveObservable = this.isEditMode ?
        this.userService.updateUser(userPayload) :
        this.userService.createUser(userPayload);

      saveObservable.subscribe({
        next: () => {
          this.dialogRef.close({success: true});
          this.openSnackBar(this.translate.instant('adminPage.messages.saveSuccess'), SnackBarType.success);
          this.isLoading = false;
        },
        error: (error) => {
          this.handleSaveError(error);
          this.isLoading = false;
        }
      })
    }
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.userForm.get(fieldName);
    return control.invalid && (control.dirty || control.touched);
  }

  isDisabled(): boolean {
    const fieldsToCheck: string[] = [
      'idCode', 'doctorCode', 'firstName', 'lastName',
      ...(this.userForm.get('mfaEnabled').value ? ['email', 'phoneNumber', 'password'] : [])
    ];

    return fieldsToCheck.some(fieldName => this.userForm.get(fieldName).invalid) || this.isLoading;
  }

  userRoleNotAg() {
    return this.userDetails.role !== Role.AG;
  }

  displayInstitutionName(institution?: Institution): string {
    return institution.name ? institution.name : '';
  }

  displayDoctorCodeWithInstitution(userInstitution: UserInstitution) {
    const institution = userInstitution.institutionName ? userInstitution.institutionName : userInstitution.institutionCode;
    return userInstitution.doctorCode + ' - ' + institution;
  }

  getErrorMessage(fieldName: string): string {
    const validationControl = this.userForm.get(fieldName);
    if (!validationControl) {
      return '';
    }
    if (validationControl.hasError('required')) {
      return this.translate.instant('createOrUpdateUserDialog.validationErrors.required');
    }
    if (validationControl.hasError('pattern')) {
      return this.getPatternErrorMessage(fieldName);
    }
    return '';
  }

  private getPatternErrorMessage(validation: string): string {
    switch (validation) {
      case 'idCode':
        return this.translate.instant('createOrUpdateUserDialog.validationErrors.idCode');
      case 'doctorCode':
        return this.translate.instant('createOrUpdateUserDialog.validationErrors.doctorCode');
      case 'firstName':
      case 'lastName':
        return this.translate.instant('createOrUpdateUserDialog.validationErrors.firstName');
      case 'email':
        return this.translate.instant('createOrUpdateUserDialog.validationErrors.email');
      case 'phoneNumber':
        return this.translate.instant('createOrUpdateUserDialog.validationErrors.phoneNumber');
      case 'password':
        return this.translate.instant('createOrUpdateUserDialog.validationErrors.password');
      default:
        return '';
    }
  }

  handleSaveError(error: any) {
    let translationKey: string;
    if (error) {
      switch (error) {
        case ID_CODE_IN_USE:
          translationKey = 'adminPage.apiErrors.idCodeInUse';
          break;
        case DOCTOR_CODE_IN_USE:
          translationKey = 'adminPage.apiErrors.doctorCodeInUse';
          break;
        case EMAIL_IN_USE:
          translationKey = 'adminPage.apiErrors.emailInUse';
          break;
        case PHONE_NUMBER_IN_USE:
          translationKey = 'adminPage.apiErrors.phoneNumberInUse';
          break;
        default:
          translationKey = 'adminPage.apiErrors.saveChangesFailed';
          break;
      }
    }
    const translatedMessage = this.translate.instant(translationKey);
    this.openSnackBar(translatedMessage, SnackBarType.error);
  }

  openSnackBar(message: string, type: SnackBarType) {
    const config = snackbarConfig(type);
    this.snackBar.open(message, null, config);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}






import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Institution} from '../models/institution';
import {UserInstitution} from '../models/user-institution';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  private baseUrlInstitution = 'api/v1/institutions';
  private baseUrlUserInstitution = 'api/v1/userInstitutions';

  constructor(private httpClient: HttpClient) {
  }

  searchInstitutions(query: string) {
    return this.httpClient.get<Institution[]>(this.baseUrlInstitution + '/search', {params: {query:query}});
  }

  searchUserInstitutions(userId: number, query: string) {
    const params = {query, ...(userId && {userId})};
    return this.httpClient.get<UserInstitution[]>(this.baseUrlUserInstitution + '/search', {params});
  }
}

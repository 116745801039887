import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../models/page';
import {Result} from '../models/result';
import {Patient} from '../models/patient';
import {ResultFile} from '../models/result-file';
import {MatSnackBar} from '@angular/material/snack-bar';
import {snackbarConfig, SnackBarType} from '../utils/snackbar-utils';

@Injectable({
  providedIn: 'root'
})
export class ResultService {

  private baseUrlResult = 'api/v1/result';

  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar) {
  }

  getResults(params: any): Observable<Page<Result<Patient>>> {
    return this.httpClient.get<Page<Result<Patient>>>(this.baseUrlResult, {params});
  }

  deleteResult(id: number) {
    return this.httpClient.delete(`${this.baseUrlResult}/${id}`);
  }

  getResultPdf(id: number) {
    return this.httpClient.get<ResultFile>(`${this.baseUrlResult}/${id}/pdf`, {responseType: 'json'});
  }

  notify(message: string, type: SnackBarType) {
    const config = snackbarConfig(type);
    this.snackBar.open(message, null, config);
  }
}

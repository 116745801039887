import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {AppRoutes} from "../utils/app-routes";


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authenticatedUser = this.authenticationService.authenticatedUserValue;
    if (authenticatedUser) {
      if (route.data.roles && route.data.roles.indexOf(authenticatedUser.role) === -1) {
        this.router.navigate([AppRoutes.Home]);
        return false;
      }
      return true;
    }
    this.router.navigate([AppRoutes.Home]);
    return false;
  }
}

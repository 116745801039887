<div>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Admin menu buttons">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <a routerLink="/results">
      <button mat-menu-item>
        <mat-icon>view_list</mat-icon>
        <span>{{ 'header.results' | translate }}</span>
      </button>
    </a>
    <a routerLink="/invoice-extras" *ngIf="isAdminOrAg()">
      <button mat-menu-item>
        <mat-icon>payment</mat-icon>
        <span>{{ 'header.invoiceExtras' | translate }}</span>
      </button>
    </a>
    <a routerLink="/admin" *ngIf="isAdmin()">
      <button mat-menu-item>
        <mat-icon>people</mat-icon>
        <span>{{ 'header.users' | translate }}</span>
      </button>
    </a>
    <a *ngIf="isMfaEnabled()">
      <button mat-menu-item (click)="openDialog()">
        <mat-icon>lock</mat-icon>
        <span>{{ 'header.passwordChange' | translate }}</span>
      </button>
    </a>
    <button mat-menu-item (click)="logout()">
      <mat-icon>close</mat-icon>
      <span>{{ 'header.logout' | translate }}</span>
    </button>
  </mat-menu>
</div>
export const INVALID_CREDENTIALS = 'Invalid email or password.'
export const INVALID_PIN = "The PIN code does not match the PIN code sent.";
export const EXPIRED = "The PIN code has expired. Please request for a new PIN code.";
export const THROTTLED = "Too many verification calls made for this Verification Session.";
export const ID_CODE_IN_USE = 'Id code is either missing or is already in use';
export const DOCTOR_CODE_IN_USE = 'Doctor code is either missing or is already in use';
export const EMAIL_IN_USE = 'Email is either missing or is already in use';
export const PHONE_NUMBER_IN_USE = 'Phone number is either missing or is already in use';

export const PASSWORD_CHANGE_FAILED = 'Password change failed. Please verify your inputs';
export const PASSWORD_SAME_AS_CURRENT = 'The new password cannot be the same as your current password';

import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Requester} from "../../models/user";
import {FormControl} from "@angular/forms";
import {map, Observable, startWith, Subject} from "rxjs";
import {AccessRightsService} from "../../services/access-rights.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-requester-filter-dropdown',
  templateUrl: './requester-filter-dropdown.component.html',
  styleUrls: ['./requester-filter-dropdown.component.css']
})
export class RequesterFilterDropdownComponent implements OnInit, OnDestroy {
  @Output() selectedDoctorCode = new EventEmitter<string>();

  requesterCtrl: FormControl = new FormControl();
  requesterFilterCtrl: FormControl = new FormControl();
  filteredRequesters: Observable<Requester[]>;
  requesters: Requester[];

  private onDestroy = new Subject<void>();

  constructor(private accessRightsService: AccessRightsService) { }

  ngOnInit(): void {
    this.accessRightsService.getAccessibleUsersForResults().subscribe((requestersList: Requester[]) => {
      this.requesters = requestersList;
      this.initializeFilter();
    });

    this.requesterCtrl.setValue('');

    this.requesterCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(value => {
      this.selectedDoctorCode.emit(value);
    });
  }

  initializeFilter() {
    this.filteredRequesters = this.requesterFilterCtrl.valueChanges
      .pipe(
        startWith(''),
        map(search => this.filterRequesters(search))
      );
  }

  filterRequesters(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.requesters.filter(requester =>
      requester.firstName?.toLowerCase().includes(filterValue) ||
      requester.lastName?.toLowerCase().includes(filterValue) ||
      requester.doctorCode?.toLowerCase().includes(filterValue));
  }

  displayRequesterNameAndCode(requester: Requester): string {
    const fullName = [requester.firstName, requester.lastName].filter(Boolean).join(' ');
    return fullName && requester.doctorCode ? `${fullName} - ${requester.doctorCode}`
      : fullName || requester.doctorCode || '';
  }

  resetField(): void {
    this.requesterCtrl.setValue('');
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
